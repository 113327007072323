$(($) => {

	const allSubItems = $('footer .footer__menus .navbar-nav>.menu-item-has-children');

	allSubItems.append('<span class="footer-menu-toggle-button"></span>');


	const calculateHeights = () => {
		$('footer').removeClass('footer-loaded');

		allSubItems.each((i, subItem) => {
			subMenu = $(subItem).find('>ul.sub-menu');
			subMenu.css({height: subMenu.height()});
		})

		$('footer').addClass('footer-loaded');
	}

	calculateHeights();

	$('.footer-menu-toggle-button').on('click', (e) => {
		const clickedItem = $(e.target).parent();
		const exist = clickedItem.hasClass('footer-menu-opened');
		$('.footer-menu-opened').removeClass('footer-menu-opened');
		if(!exist)
			clickedItem.addClass('footer-menu-opened');
	});

});
