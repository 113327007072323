$(() => {
  const activeClassName = "bs-active";
  const focusedClassName = "bs-focused";

  const initSlick = () => {
    if ($(".bs-slider-accordion [data-slick]").length) {
      $(".bs-slider-accordion [data-slick]").not(".slick-initialized").slick();
    }
  };

  $(".bs-slider-accordion-slick").each((i, accordion_element) => {
    const accordionId = $(accordion_element).attr("id");
    $(`#${accordionId}_container .bs-slider-accordion-slick`).on(
      "afterChange",
      function (event, slick, currentSlide) {
        const target = $(`#${accordionId} .slick-current`)
          .find(".accordion__block__btn")
          .data("target");
        const floatingPanel = $(
          `#${accordionId}_container .bs-slider-accordion__floating-panel[data-parent='${target}']`
        );

        if (!$(slick.$slides[currentSlide]).hasClass(focusedClassName)) {
          //add active class to current slick active item
          $(`#${accordionId} .card`).removeClass(activeClassName);
          $(`#${accordionId} .slick-current .card`).addClass(activeClassName);

          //add show class to active block btn
          $(`#${accordionId} .card div`).removeClass("show");
          $(`#${accordionId} .slick-current ${target}`).addClass("show");
        }

        //Removing focused class from all the slides after the transition
        $(slick.$slides).removeClass(focusedClassName);

        //add show class to folting panel
        if (target && floatingPanel) {
          floatingPanel.siblings().removeClass("show");
          floatingPanel.addClass("show");
        }
        $(`#${accordionId} .bs-slider-accordion-slick`).slick('refresh');
      }
    );
  });

  // handle resize event
  const handleResize = () => {
    initSlick();
  };

  // Calls on window resize
	let windowWidth = window.innerWidth;
	$(window).on( 'resize', () => {
    if( windowWidth !== window.innerWidth ) {
			debounce(handleResize, 500);
    }
    windowWidth = window.innerWidth;
	});

  initSlick();
});