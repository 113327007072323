$(($) => {
	// Fix leadership page content cut off issue
	$('.bs-section--leadership-contents img:last').one('load', function () {
		if ($('.bs-section--leadership-contents [data-slick]').length) {
			$('.bs-section--leadership-contents [data-slick]')
				.not('.bs-slider-accordion-slick')
				.not('.slick-initialized')
				.slick();
		}
	});

	if ($('.bs-slider-accordion-slick.slick-initialized').length) {
		$('.bs-slider-accordion-slick.slick-initialized').slick('resize');
	}

});
