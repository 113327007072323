// eslint-disable-next-line no-undef
$(($) => {
	const header = $('header');

	//Add Class on Scroll
	$(window).scroll(() => {
		if (window.pageYOffset >= 1) {
			header.addClass('scrolled');
		} else {
			header.removeClass('scrolled');
		}
	});

	//mobile close one menu on other expand
	$('header .mega-indicator').on('click', (e) => {
		let $link = $(e.target);
		let $parent = $link.parents('.mega-menu-item');
		if (!$parent.hasClass('mega-toggle-on')) {
			$parent.removeClass('mega-toggle-on');
			$link.parent().attr('aria-expanded', false);
		} else {
			$('.mega-toggle-on a').attr('aria-expanded', false);
			$('.mega-toggle-on').removeClass('mega-toggle-on');
			$parent.addClass('mega-toggle-on');
			$link.parent().attr('aria-expanded', true);
		}
	});

	let topMenuReplace = () => {
		const $topMenu = $('.menu-top-menu-container');
		const $menuItems = $topMenu.find('ul li');
		const $megaMenu = $('#mega-menu-main-menu');
		let menuReplaced = true;

		$(window).on('load resize', function (e) {
			if ($(window).width() < 1200) {
				if (menuReplaced) {
					$topMenu.hide();
					$menuItems.addClass('mega-menu-item temp-menu-item');
					$megaMenu
						.find('> li:last-of-type')
						.before($menuItems.clone());
					$menuItems.find('a').addClass('mega-menu-link');
					menuReplaced = false;
				}
			} else if (!menuReplaced) {
				$topMenu.show();
				$menuItems.removeClass('mega-menu-item temp-menu-item');
				$menuItems.find('a').addClass('mega-menu-link');
				$('#mega-menu-main-menu').find('.temp-menu-item').remove();
				menuReplaced = true;
			}
		});
	};

	topMenuReplace();

	header.find('.header__navigation.main-menu ul.mega-menu>li.mega-menu-item-has-children').each((i, firstLevelItem) => {
		firstLevelItem = $(firstLevelItem);
		const secondLevelList = [];
		firstLevelItem.find('>.mega-sub-menu>.mega-menu-row>.mega-sub-menu').children().each((j, secondLevelItem) => {
			const li = $(secondLevelItem).find('>.mega-sub-menu>li');
			if (li.hasClass('mega-menu-item-object-page') || li.hasClass('mega-menu-item-object-custom')) secondLevelList.push(li);
		});
		if(secondLevelList.length === 1){
			const secondLevelItemHref = secondLevelList[0].find('>a').attr('href');
			if(secondLevelItemHref === '#') secondLevelList[0].addClass('has-dummy-second-level');
		}
	});

	let lastScrollPos = 0;
	$('body').on('click', () => {
		setTimeout(() => {
			if($('body').hasClass('mega-menu-main-menu-mobile-open')) {
				lastScrollPos = $(window).scrollTop();
				$('html').css({top: lastScrollPos}).addClass('header-menu-open');
			} else {
				if($('html').hasClass('header-menu-open')) {
					$('html').removeClass('header-menu-open');
					$('html, body').css({top: ''}).scrollTop(lastScrollPos);
				}
			}
		}, 20);
	});
});
