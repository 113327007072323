// eslint-disable-next-line no-undef
$(($) => {
   $('a[href^="#"]').on('click', (e) => {
   const $this = $(e.target);
   const anchor = $this.attr('href');
   if(!$this.parents('section').hasClass('bs-section--inner-scroll-section')) {
      if (anchor.length) {
         e.preventDefault();
         const headerheight = $('header').height();
			let adminBarheight = $("#wpadminbar").length ? $("#wpadminbar").height() : 0;
         const scrollSection = $(anchor).offset().top - headerheight - adminBarheight;
         $('html, body').stop().animate({
            scrollTop: scrollSection
         }, 1000);
      }
   }
   });
});