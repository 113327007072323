$(($) => {
	if ($('.bs-section--inner-scroll-section').length > 0) {
		if (!!window.IntersectionObserver) {
			// Inner-menu section Observer
			const allSections = document.querySelectorAll('section');
			const screenHeight = window.innerHeight / 2;
			// configurations
			const config = {
				root: null,
				rootMargin: '-150px 0px -' + screenHeight + 'px 0px',
				threshold: 0.1,
			};

			const innerMenuObserver = new IntersectionObserver(function (
				entries
			) {
				entries.forEach((entry) => {
					if (entry.isIntersecting) {
						// get current section id
						const currentId = entry.target.id;
						const currentLink = document.querySelectorAll(
							'.bs-section--inner-scroll-section [href="#' +
								currentId +
								'"]'
						);

						if (currentLink.length !== 0) {
							removeRestOfLinkClasses();
							currentLink[0].classList.add('active');
						} else {
							removeRestOfLinkClasses();
						}
					}
				});
			},
			config);

			if (allSections.length !== 0) {
				allSections.forEach(function (section) {
					innerMenuObserver.observe(section);
				});
			}

			const linkElements = document.querySelectorAll(
				'.bs-section--inner-scroll-section a'
			); // all links in inner menu
			linkElements.forEach((elem, index) => {
				linkElements[index].addEventListener('click', (e) => {
					e.preventDefault();
					const clickedSectionId = linkElements[
						index
					].attributes.href.value.replace('#', '');

					const scrollSection = document.querySelectorAll(
						'[id="' + clickedSectionId + '"]'
					)[0].offsetTop;
					$('html, body').animate(
						{
							scrollTop: scrollSection - 120,
						},
						400
					);
				});
			});

			const removeRestOfLinkClasses = () => {
				linkElements.forEach((link, i) => {
					linkElements[i].classList.remove('active');
				});
			};
		}

		$(window).scroll(() => {
			const headerheight = $('header').height();
			let adminBarheight = $("#wpadminbar").length ? $("#wpadminbar").height() : 0;
			const stickyElement = document.querySelector(
				'.bs-section--inner-scroll-section'
			);
			if(stickyElement) {
				$(".bs-section--inner-scroll-section").css("top", headerheight + adminBarheight);
				if (window.scrollY >= 100) stickyElement.classList.add('stuck');
				else stickyElement.classList.remove('stuck');
			}
		});
		$('.notify-bar').on('DOMSubtreeModified', () => {
			$('.bs-section--inner-scroll-section').css('top', '102px');
		});
	}
});
