// eslint-disable-next-line no-undef
$(($) => {
  // Adding no-tag class when there are no tags
  const blogInnerTagsDiv = $('.bs-div--blog-inner-tags');
  if (blogInnerTagsDiv.find('span').length === 0) {
    blogInnerTagsDiv.addClass('no-tags')
  }

  // Remove extra paragraph tags
  const blogInnerContent = $('.bs-section--blog-inner-content');
  if (blogInnerContent.length) {
    blogInnerContent.find('p').each((i, e) => {
      if ($(e).html() == '' || $(e).html().trim() == '&nbsp;') {
        $(e).remove();
      }

    });
    blogInnerContent.find('p:first br:first, p:last br:last').remove();

    // External links open in new tab
    blogInnerContent.find('a').each((index, ele) => {
      const currentOrigin = location.origin;
      const url = new URL($(ele).attr('href'), currentOrigin);
      if (currentOrigin !== url.origin) {
        $(ele).attr('target', '_blank');
      }
    });
  }

  // Remove extra space remove in careers
  const careerInnerContent = $('.bs-section--careers-inner-content');
  if (careerInnerContent.length) {
    careerInnerContent.find('p').each((i, e) => {
      if ($(e).html().trim() == '<br>' ) {
        $(e).remove();
      }
      if ($(e).find('span').length) {
        if ($(e).find('span').html().trim() == '' || $(e).find('span').html().trim() == '&nbsp;') {
          $(e).remove();
        }
      }
    });
  }
});