import lottie from 'lottie-web';
(($) => {
	$('.lottie-animation-block').each((index, element) => {
		const animationDiv = $(element)[0];
		const animation = lottie.loadAnimation({
			container: animationDiv,
			renderer: 'svg',
			loop: true,
			autoplay: true,
			path: $(element).data('src'),
		});

		animation.addEventListener('DOMLoaded', function () {
			loopHover(animation, animationDiv);
		});

	});

	// ----------------------- popup ---------------------

	function loopHover(animation, animationDiv) {
		$(animationDiv).find('.loop-shape').each(function () {
			const loopShapeId = $(this).attr('id').split("_")[0];
			const loopElmnts = $('[id^=' + loopShapeId + '_tooltip]');
			$(this).on('mouseenter', () => {
				animation.pause();
				loopElmnts.each(function () {
					$(this).css({
						opacity: '1',
						transition: 'opacity 0.2s ease-in',
					});
				});
			});
			$(this).on('mouseleave', () => {
				animation.play();
				loopElmnts.each(function () {
					$(this).css({
						opacity: '0',
						transition: 'opacity 0.2s ease-in',
					});
				});
			});
		});
	}
})(jQuery);
